import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";

import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import ErrorIcon from "@material-ui/icons/Error";

import "../css/anim-bounce.css";
import wallaApi from "../api/walla";

const useStyles = makeStyles((theme) => ({
  root: {},
  dlgBar: { position: "relative" },
  title: {
    flex: 1,
    textAlign: "center",
  },
  dlgPaper: {
    background:
      "radial-gradient(circle,rgba(40, 44, 52, 1) 0%,rgba(17, 17, 17, 1) 100%)",
  },
  formContainerIntro: { paddingTop: theme.spacing(5) },
  formContainer: { paddingBottom: theme.spacing(5) },
  formText: {
    color: "#f1f1f1",
    textAlign: "center",
  },
  input: {
    backgroundColor: "rgb(40, 44, 52)",
    color: theme.palette.primary.main,
  },
  inputFocused: {
    backgroundColor: "rgb(40, 44, 52)",
    color: "#f1f1f1",
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formHelp: {
    marginLeft: theme.spacing(1),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DlgContact = ({ open = "false", actionClose }) => {
  const classes = useStyles();
  const [isBusy, setBusy] = React.useState(false);
  const [emailErr, setEmailErr] = React.useState("");
  const [msgErr, setMsgErr] = React.useState("");
  const [formErr, setFormErr] = React.useState("");
  const [showForm, setShowForm] = React.useState(true);
  const [values, setValues] = React.useState({
    name: "",
    email: "",
    msg: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (prop === "email") setEmailErr("");
    else if (prop === "msg") setMsgErr("");
  };

  const sendFeedback = async () => {
    var err = false;
    setBusy(true);

    // Validate email
    let reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (values["email"] === "") {
      setEmailErr("Please enter your email address.");
      err = true;
    } else if (reg.test(values["email"]) === false) {
      setEmailErr("Invalid email address.");
      err = true;
    } else setEmailErr("");

    // Validate message
    if (values["msg"] === "") {
      setMsgErr("Please write something to share.");
      err = true;
    } else setMsgErr("");

    // Check everything
    if (err) {
      // Something went wrong
      if (
        document.getElementById("form") !== null &&
        document.getElementById("form") !== undefined
      ) {
        document.getElementById("form").classList.add("bounceAnimation");
        setTimeout(() => {
          document.getElementById("form").classList.remove("bounceAnimation");
        }, 500);
        setFormErr("");
        setBusy(false);
      }
    } else {
      // Submit the form
      try {
        // Submit Feedback
        const response = await wallaApi.post("/feedback/submit/", {
          email: values["email"],
          name: values["name"],
          message: values["msg"],
        });
        actionClose();
      } catch (error) {
        if (error.response) setFormErr(error.response.data.message);
        else
          setFormErr("Service Unavailable. Please try again later. " + error);
        console.log(error);
      }
      setBusy(false);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth={true}
        fullScreen="true"
        TransitionComponent={Transition}
        onClose={actionClose}
        open={open}
        classes={{ paper: classes.dlgPaper }}
      >
        <AppBar className={classes.dlgBar} position="fixed">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={actionClose}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Let's Talk
            </Typography>
            <IconButton
              color="inherit"
              disabled={isBusy}
              onClick={() => sendFeedback()}
            >
              <SendIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Container maxWidth="sm" className={classes.formContainerIntro}>
            <Typography
              variant="h6"
              classes={{ root: classes.formText }}
              gutterBottom
            >
              You got yourself here, so we are anxiously waiting how we can help
              you with your next project.
            </Typography>
          </Container>
          <Container maxWidth="md" className={classes.formContainer}>
            <form id="form" className={classes.form} noValidate>
              <Grid container spacing={3} justify="center">
                <Grid item xs={12}>
                  <FormControl variant="outlined" required fullWidth>
                    <InputLabel
                      htmlFor="email"
                      classes={{
                        formControl: classes.input,
                        focused: classes.inputFocused,
                      }}
                    >
                      Email
                    </InputLabel>
                    <OutlinedInput
                      id="email"
                      type="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      labelWidth={60}
                      disabled={isBusy}
                      error={emailErr || formErr ? true : false}
                      classes={{
                        input: classes.inputFocused,
                        focused: classes.inputFocused,
                      }}
                    />
                  </FormControl>
                  {emailErr ? (
                    <div style={{ display: "flex" }}>
                      <ErrorIcon
                        color="error"
                        style={{ fontSize: 18, marginTop: "2px" }}
                      />
                      <FormHelperText
                        error={true}
                        classes={{ error: classes.formHelp }}
                      >
                        {emailErr}
                      </FormHelperText>
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel
                      htmlFor="name"
                      classes={{
                        formControl: classes.input,
                        focused: classes.inputFocused,
                      }}
                    >
                      Name
                    </InputLabel>
                    <OutlinedInput
                      id="name"
                      type="name"
                      value={values.name}
                      onChange={handleChange("name")}
                      labelWidth={45}
                      disabled={isBusy}
                      error={formErr ? true : false}
                      classes={{
                        input: classes.inputFocused,
                        focused: classes.inputFocused,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    variant="outlined"
                    required
                    fullWidth
                    classes={{
                      root: classes.inputFocused,
                    }}
                  >
                    <InputLabel
                      htmlFor="msg"
                      classes={{
                        formControl: classes.input,
                        focused: classes.inputFocused,
                      }}
                    >
                      Message
                    </InputLabel>
                    <OutlinedInput
                      id="msg"
                      type="msg"
                      value={values.msg}
                      multiline
                      rows={3}
                      rowsMax={6}
                      onChange={handleChange("msg")}
                      labelWidth={80}
                      disabled={isBusy}
                      error={msgErr || formErr ? true : false}
                      classes={{
                        input: classes.inputFocused,
                        focused: classes.inputFocused,
                      }}
                    />
                  </FormControl>
                  {msgErr || formErr ? (
                    <div style={{ display: "flex" }}>
                      <ErrorIcon
                        color="error"
                        style={{ fontSize: 18, marginTop: "2px" }}
                      />
                      <FormHelperText
                        error={true}
                        classes={{ root: classes.formHelp }}
                      >
                        {formErr ? formErr : msgErr}
                      </FormHelperText>
                    </div>
                  ) : null}
                </Grid>
                <Grid item>
                  <div className={classes.wrapper}>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => sendFeedback()}
                      disabled={isBusy}
                      startIcon={<SendIcon />}
                    >
                      Send
                    </Button>
                    {isBusy && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </form>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DlgContact;
