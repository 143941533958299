import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";

import logo from "./assets/walla.svg";
import crowd from "./assets/crowd.svg";

import DlgContact from "./components/DlgContact";

import "./App.css";

const useStyles = makeStyles((theme) => ({
  smBox: {
    zIndex: "9",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  smBtn: {},
  smIcon: {
    fontSize: "2rem",
  },
}));

const App = () => {
  const classes = useStyles();
  const [openContact, setOpenContact] = React.useState(false);

  const handleClickOpen = () => {
    setOpenContact(true);
  };
  const handleClose = () => {
    setOpenContact(false);
  };

  return (
    <div className="App">
      <DlgContact open={openContact} actionClose={handleClose} />
      <header className="App-header">
        <div className="pulse">
          <Button className={classes.actionBtn} onClick={handleClickOpen}>
            <img src={logo} className="App-logo" alt="logo" />
          </Button>
        </div>
        <div id="smIcons" className={classes.smBox}>
          <Fab
            color="primary"
            aria-label="add"
            classes={{ primary: classes.smBtn }}
            href="https://www.facebook.com/WallaFilmsZA"
          >
            <FacebookIcon className={classes.smIcon} />
          </Fab>
          <Fab
            color="primary"
            aria-label="add"
            href="https://www.instagram.com/walla_films/"
          >
            <InstagramIcon className={classes.smIcon} />
          </Fab>
          <Fab
            color="primary"
            aria-label="add"
            href="https://www.youtube.com/channel/UCUaZdkPEnisIlxPs2iT-HoA"
          >
            <YouTubeIcon className={classes.smIcon} />
          </Fab>
        </div>
      </header>
      <div className="crowdContainer">
        <div className="crowdMain">
          <img src={crowd} className="crowd" alt="Crowd" />
        </div>
      </div>
      <footer className="App-footer">
        &copy; {new Date().getFullYear()} Walla Films Pty. Ltd.
      </footer>
    </div>
  );
};

export default App;
