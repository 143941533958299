import axios from "axios";

export const SITE_ROOT = "/";

const baseURL = "https://www.wallafilms.com/api/v1";
//const baseURL = "http://localhost:8080/api/v1";

const instance = axios.create({
  baseURL: baseURL,
});

export default instance;
